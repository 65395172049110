.projects__group {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  align-items: flex-start;
  row-gap: 1rem;
}

.projects__box {
  display: flex;
  justify-content: start;
  column-gap: 2.5rem;
}

.about__subtitle {
  font-size: var(--small-font-size);
  text-align: center;
}

.projects__container {
  grid-template-columns: repeat(1, 1fr);
  column-gap: 3rem;
  justify-content: center;
}

.projects__info {
  text-align: justify;
  margin-bottom: 1rem;
}

.projects__contents {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 2rem 1.5rem;
  border-radius: 1.25rem;
}

.projects__title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  text-align: center;
  margin-bottom: var(--mb-0-5);
}

.projects__data {
  display: flex;
  align-items: start;
  gap: 0.5rem;
}

.projects .bx-badge-chek {
  font-size: 1rem;
  color: var(--title-color);
}

.projects__name {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  line-height: 18px;
}

.projects__level {
  font-size: var(--small-font-size);
}

.project__buttons {
  display: flex;
  justify-content: center;
  column-gap: 5rem;
  font-size: 1.5rem;
  margin-top: 2rem;
}

.project__buttons a {
  color: var(--title-color);
}

.project__buttons a:hover {
  color: rgb(129, 129, 32);
}

.Hero_img {
  width: 100%;
  border-radius: 10px;
  /* box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px; */
  border: none;
  outline: none;
}

@media screen and (max-width: 992px) {
  .projects__container {
    grid-template-columns: 1fr;
    row-gap: 2rem;
  }
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 576px) {
  .projects__container {
    grid-template-columns: 1fr;
    row-gap: 2rem;
  }

  .projects__content {
    padding: 1.5rem;
  }
}

@media screen and (max-width: 350px) {
  .projects__box {
    column-gap: 1.25rem;
  }

  .projects__name {
    font-size: var(--small-font-size);
  }
}
